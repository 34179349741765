import React from 'react';
import { connect } from 'react-redux';
import { Button, SearchInput, DataTable, Pager } from 'tyb';
import enums from '@/assets/enum.json';
import queryString from 'query-string';
import { formatDate } from 'ucode-utils';
const { isvUserWhitelists, isvUserWhitelistsStatus } = iceStarkData.store.get('commonAction')?.isvUser;
import ModifyWhitelist from './Components/ModifyWhitelist';


import './index.scss';

@connect(
    state => ({
        permissionIds: state.users.permissionIds||[],
        loading: state.isvUser.loading,
        isvUserWhitelists: state.isvUser.isvUserWhitelists,
        isvUserWhitelistsStatusLoaded: state.isvUser.isvUserWhitelistsStatusLoaded,
    }),
    {
        getIsvUserWhitelists: isvUserWhitelists.REQUEST,
        setIsvUserWhitelistsStatus: isvUserWhitelistsStatus.REQUEST,
    })
export default class WhitelistSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: [],
        };
        this.params = { page: 0, size: 20 };
        this.status = { ENABLE: '禁用', DISABLE: '启用' };
        this.columns = [
            {
                key: 'ip',
                dataIndex: 'ip',
                title: '访问IP',
            },
            {
                key: 'createdName',
                dataIndex: 'createdName',
                title: '创建者',
            },
            {
                key: 'remark',
                dataIndex: 'remark',
                title: '备注',
            },
            {
                key: 'createdTime',
                dataIndex: 'createdTime',
                title: '创建时间',
                render: (val) => <div>{formatDate(val)}</div>
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: '状态',
                // render: (val) => <div>{enums.status[val] ? enums.status[val].name : ''}</div>
                render: (val) => <div>{val == 'ENABLE' ? '有效' : '无效'}</div>
            },
            {
                key: 'operate',
                dataIndex: 'operate',
                title: '操作',
                render: (val, record) => (
                    <div className="operate">
                        {this.props.permissionIds.includes('ws.isvUser.edit') && <a href="javascript:;" onClick={() => { this.handleVisible(record) }}>编辑</a>}
                        {this.props.permissionIds.includes('ws.isvUser.status') && <a href="javascript:;" onClick={() => { this.handleOperate(record) }}>{this.status[record.status]}</a>}
                        {/* {this.props.permissionIds.includes('ws.isvUser.edit') && <a href="javascript:;" onClick={() => { this.handleOperate('del', record) }}>删除</a>} */}
                    </div>)
            },
        ]
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        const { userId, name } = query || {};
        if (userId) {
            this.params.userId = userId;
            this.props.getIsvUserWhitelists(this.params);
        }
        this.setState({ name });
    }

    componentDidUpdate(preProps) {
        if ((/2\d{2}$/.test(this.props.isvUserWhitelistsStatusLoaded) && this.props.isvUserWhitelistsStatusLoaded !== preProps.isvUserWhitelistsStatusLoaded)) {
            this.props.getIsvUserWhitelists(this.params);
        }
    }

    getColumns = () => {
        return this.columns.map(column => {
            if (column.key === 'status') {
                return {
                    ...column,
                    headerRender: this.renderStatusHeader
                }
            } else {
                return column;
            }
        })
    }

    renderStatusHeader = (col) => {
        const list = [
            { text: '有效', value: 'ENABLE' },
            { text: '无效', value: 'DISABLE' },
        ];

        return (
            <DataTable.FilterableColumnHeader
                list={list}
                value={this.state.status}
                onFilter={this.handleStatusFilter}
            >
                {col.title}
            </DataTable.FilterableColumnHeader>
        )
    }

    handleStatusFilter = (value) => {
        this.setState({ status: value });
        this.params.status = value;
        this.params.page = 0;
        this.props.getIsvUserWhitelists(this.params);
    }

    search = () => {
        this.setState({
            page: 0,
        });
        this.params.page = 0;
        this.props.getIsvUserWhitelists(this.params);
    }

    onPageChange = (val) => {
        this.setState({ page: val });
        this.params.page = val;
        this.props.getIsvUserWhitelists(this.params);
    }

    onPerPageChange = (val) => {
        this.setState({ perPageCount: val });
        this.params.size = val;
        this.params.page = 0;
        this.props.getIsvUserWhitelists(this.params);
    }

    handleVisible = (record, refulsh) => {
        this.setState({ visible: !this.state.visible, record: { ...record, userId: this.params.userId } });
        if (refulsh) {
            this.props.getIsvUserWhitelists(this.params);
        }
    }

    handleOperate = (record) => {
        this.props.setIsvUserWhitelistsStatus({ id: record.id, status: record.status == 'ENABLE' ? 'DISABLE' : 'ENABLE' });
    }

    render() {
        const { keywords, page, perPageCount = 20, visible, record, name } = this.state;
        const { permissionIds, loading, isvUserWhitelists = {} } = this.props;
        const { content, totalElements } = isvUserWhitelists;
        return (
            <React.Fragment>
                <div className="">
                    <div className="search-bar">
                        {permissionIds.includes('ws.isvUser.add') && <Button onClick={() => { this.handleVisible(); }}>创建</Button>}
                        <div className="search-input-block">
                            <SearchInput
                                onChange={(val) => { this.params.keywords = val; this.setState({ keywords: val }) }}
                                onSearch={this.search}
                                value={keywords}
                                placeholder="搜索访问IP"
                            />
                        </div>
                    </div>
                    <div>当前账号：{name}</div>
                    <div className="container">
                        <DataTable
                            columns={this.getColumns()}
                            data={content}
                            rowKey="id"
                            loading={loading}
                            rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
                        />
                        <Pager
                            total={totalElements}
                            current={page}
                            perPageCountList={[10, 20, 50]}
                            perPageCount={perPageCount}
                            onPageChange={this.onPageChange}
                            onPerPageChange={this.onPerPageChange}
                        />
                    </div>
                </div>
                <ModifyWhitelist
                    record={record}
                    visible={visible}
                    handleVisible={this.handleVisible}
                />
            </React.Fragment>
        )
    }
}




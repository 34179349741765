import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Input, FormGroupList } from 'tyb';
import { FormGroupField } from 'tyb';
import { reduxForm, FieldArray } from 'redux-form';
const { isvUserWhitelistsModify } = iceStarkData.store.get('commonAction')?.isvUser;
import '../index.scss';


// 创建规则 
@connect(
    state => ({
        form: state.form.ModifyFieldForm,
        isvUserWhitelistsModifyLoaded: state.isvUser.isvUserWhitelistsModifyLoaded,
    }),
    {
        setIsvUserWhitelistsModify: isvUserWhitelistsModify.REQUEST,
    }
)
class ModifyWhitelist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(preProps) {
        if (this.props.visible && this.props.visible !== preProps.visible) {
            const initialize = { ...this.props.record };
            const { id } = initialize;
            if (id) {
                initialize.whitelists = [{ ...this.props.record }];
            } else {
                initialize.whitelists = [{}];
            }
            this.props.initialize(initialize);
        }

        if ((/2\d{2}$/.test(this.props.isvUserWhitelistsModifyLoaded) && this.props.isvUserWhitelistsModifyLoaded !== preProps.isvUserWhitelistsModifyLoaded)) {
            this.props.handleVisible(undefined, true);
        }
    }

    // 提交
    handleSubmit = (data) => {
        const { id, whitelists } = data;
        whitelists.forEach(val => {
            val.ip = val.ip.trim();
        });
        this.props.setIsvUserWhitelistsModify(id ? { ...whitelists[0] } : data);
    }

    render() {
        const { visible, record = {}, handleVisible, handleSubmit } = this.props;
        return (
            <Dialog
                className="ModifyWhitelistForm"
                title="创建规则"
                visible={visible}
                onCancel={() => { handleVisible(record); }}
                onOk={handleSubmit(this.handleSubmit)}
            >
                <div>
                    <FormGroupList>
                        <FieldArray name="whitelists" component={FieldItem}
                            fieldItems={record.id}
                        />
                    </FormGroupList>

                </div>
            </Dialog>
        )
    }
}

export default reduxForm({
    form: 'ModifyWhitelistForm',
    initialValues: {

    },
})(ModifyWhitelist)

const requireIP = (val) => {
    if (!val || !val.trim()) {
        return 'IP地址不能为空';
    } else if (val.trim() && val.trim().length > 30) {
        return '不能超过30字符';
    }
    return null;
};

const requireRemark = (val) => {
    const reg = /\s/;
    if (val && val.trim() && val.trim().length > 1000) {
        return '不能超过1000字符';
    }
    if(reg.test(val)){
        return '不能输入空格';
    }
    return null;
};

const FieldItem = ({ fields, fieldItems }) => (
    <div>
        {fields.map((item, index) => (
            <div key={index} className="box-body">
                <div className="block">
                    <FormGroupField
                        name={`${item}.ip`}
                        label="IP"
                        component={Input}
                        validate={[requireIP]}
                        required
                    />
                    <FormGroupField
                        name={`${item}.remark`}
                        label="备注"
                        component={Input}
                        validate={[requireRemark]}
                    />
                    {fields.length > 1 && !fieldItems && <li><a onClick={(e) => fields.remove(index)}>删除</a></li>}
                </div>
            </div>
        ))}
        <div>
            {!fieldItems && <a onClick={(e) => fields.push({})} className="cursorp">新增</a>}
        </div>
    </div>
)